import React, {FC, useEffect, useState} from 'react';
import {KratosFlowType, ResponseErrors, useInitialiseKratosFlow} from '~/hooks/kratos.hooks';
import {styled} from '@mui/material/styles';
import {Button, Paper, TextField} from '@mui/material';
import {KRATOS_URL} from "~/config";
import {GoogleButton} from "~/components/GoogleButton";
import {ErrorText} from "~/styledComponents";

export const Settings: FC<unknown> = () => {

  const [googleError, setGoogleError] = useState<string>('');

  const [googleButtonText, setGoogleButtonText] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordResetHelperText, setPasswordResetHelperText] = useState<string>('');

  const {flowID, csrfToken, googleLinked, responseError, setResponseError} = useInitialiseKratosFlow(KratosFlowType.SETTINGS);

  useEffect(() => {
    if (googleLinked === true) {
      setGoogleButtonText('Unlink Google account');
    } else if (googleLinked === false) {
      setGoogleButtonText('Link Google account');
    }
  })

  const onLogoutButtonClick = async () => {
    let logoutUrl;

    const url = new URL(`${KRATOS_URL}/self-service/logout/browser`);
    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();

    if (response.status === 200) {
      logoutUrl = responseJson.logout_url;
    } else {
      console.error('Error logging out.');
      if (responseJson && responseJson.error) {
        console.error(`Reason: ${responseJson.error.message}`);
      }
    }

    const logoutResponse = await fetch(logoutUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      },
    });
    if (logoutResponse.status === 204) {
      window.location.href = '/login';
    } else {
      console.error('Error logging out.');
      const logoutResponseJson = await logoutResponse.json();
      if (logoutResponseJson && logoutResponseJson.error) {
        console.error(`Reason: ${logoutResponseJson.error.message}`);
      }
    }
  };

  const onGoogleButtonClick = async () => {
    if (!flowID || !csrfToken) {
      setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      return;
    }

    let body: {csrf_token: string, method: string, link: string | undefined, unlink: string | undefined} = {
      csrf_token: csrfToken,
      method: 'oidc',
      link: 'google',
      unlink: undefined
    };
    if (googleLinked) {
      body.link = undefined;
      body.unlink = 'google';
    }

    const url = new URL(`${KRATOS_URL}/self-service/settings`);
    url.searchParams.append('flow', flowID);
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const responseJson = await response.json();

    if (response.status === 200) {
      location.reload();
    } else if (response.status === 403 && responseJson.error && responseJson.error.id === 'session_refresh_required') {
      // check if privileged session expired
      // snackbar prompt user to log out and log back in
      setGoogleError('Session refresh required: please logout then log back in and try again.');
    } else if (response.status === 422 && responseJson.redirect_browser_to) {
      window.location.href = responseJson.redirect_browser_to;
    } else {
      // should specifically look for the ui message containing oidc, not index by 0
      if (responseJson?.ui?.messages.length > 0 && responseJson.ui.messages[0]?.id) {
        const kratosErrorCode = responseJson.ui.messages[0].id as number;
        // change this for OIDC specific errors
        switch (kratosErrorCode) {
          case 4000006:
            setResponseError(ResponseErrors.BAD_CREDENTIALS);
            break;
          default:
            setResponseError(ResponseErrors.UNKNOWN_KRATOS_ERROR);
            break;
        }
      } else {
        setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      }
    }
  };

  const handlePasswordResetSubmit = async () => {
    if (!flowID || !csrfToken) {
      setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      return;
    } else if (password !== passwordConfirm) {
      setResponseError(ResponseErrors.PASSWORDS_DONT_MATCH);
    } else if (password.length < 8) {
      setResponseError(ResponseErrors.PASSWORD_TOO_SHORT);
    } else {
      setPasswordResetHelperText('');
      const url = new URL(`${KRATOS_URL}/self-service/settings`);
      url.searchParams.append('flow', flowID);
      const response = await fetch(url.toString(), {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({password: password, csrf_token: csrfToken, method: 'password'}),
      });

      const responseJson = await response.json();

      if (response.status === 200) {
        setPasswordResetHelperText('Password reset success!');
      } else if (response.status === 403 && responseJson.error && responseJson.error.id === 'session_refresh_required') {
        setResponseError(ResponseErrors.SESSION_REFRESH_REQUIRED);
      } else {
        setPassword('');
        setPasswordConfirm('');
        let passwordResetError: {id: number} | undefined;
        responseJson.ui.nodes.forEach(node => {
          if (node.type === 'input' && node.group === 'password' && node.attributes.type == 'password') {
            if (node.messages[0]) {
              passwordResetError = node.messages[0];
            }
          }
        })
        if (passwordResetError) {
          const kratosErrorCode = passwordResetError.id;
          switch (kratosErrorCode) {
            case 4000005:
              setResponseError(ResponseErrors.PASSWORD_IN_DATA_BREACH);
              break;
            default:
              setResponseError(ResponseErrors.UNKNOWN_KRATOS_ERROR);
              break;
          }
        } else {
          setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
        }
      }
    }
  };

  // Handles hitting enter button as form submission
  useEffect(() => {
    const page = document.getElementById("settings-page")
    if (!page) {
      return console.error("login page failed to render")
    }

    const resetSubmitOnEnter = async (event: { key: string; preventDefault: () => void; }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        await handlePasswordResetSubmit();
      }
    };
    page.addEventListener("keypress", resetSubmitOnEnter)
    return () => {
      page.removeEventListener("keypress", resetSubmitOnEnter)
    }
  }, [csrfToken, password, passwordConfirm])

  return (
    <>
      <LogoutDiv>
        <Button variant={'contained'} onClick={onLogoutButtonClick}>
          Logout
        </Button>
      </LogoutDiv>
      <CenteredDiv id={'settings-page'}>
        <h1>Account Linking</h1>
        <AlignedDiv>
          <GoogleButton
            style={'dark'}
            onClick={onGoogleButtonClick}
            label={googleButtonText}
          >
          </GoogleButton>
          {googleError && <ErrorText>{googleError}</ErrorText>}
        </AlignedDiv>
        <h1>Password reset</h1>
        <AlignedDiv>
          <TextField
            error={responseError !== undefined}
            helperText={''}
            id='password-new'
            name='password-new'
            label='New Password'
            type='password'
            value={password}
            onChange={e => {
              setResponseError(undefined);
              setPassword(e.target.value);
            }
            }
          />
        </AlignedDiv>
        <AlignedDiv>
          <TextField
            error={responseError !== undefined}
            helperText={passwordResetHelperText}
            id='password-confirm'
            name='password-confirm'
            label='Confirm password'
            type='password'
            value={passwordConfirm}
            onChange={e => {
              setResponseError(undefined);
              setPasswordConfirm(e.target.value);
            }
            }
          />
          {responseError && <ErrorText>{responseError}</ErrorText>}
        </AlignedDiv>
        <AlignedDiv>
          <Button
            onClick={handlePasswordResetSubmit}
          >
            Submit
          </Button>
        </AlignedDiv>
      </CenteredDiv>
      <LogoDiv>
        <LogoImg src={'/gradient_logo.svg'}/>
      </LogoDiv>
    </>
  );
};

const CenteredDiv = styled(Paper)({
  position: 'fixed',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  display: 'grid',
  justifyContent: 'center',
  gridGap: '10px',
  padding: "10px 40px",
  overflow: 'auto'
});

const OidcAlignedDiv = styled('div')({
  display: 'block',
  justifySelf: 'center',
  alignItems: 'left',
  marginLeft: 20,
  marginRight: 20,
  '& input': {
    width: 'min(300px, 25vw)',
  },
});

const AlignedDiv = styled('div')({
  display: 'block',
  justifySelf: 'center',
  alignItems: 'center',
  marginLeft: 20,
  marginRight: 20,
  '& input': {
    width: 'min(250px, 80vw)',
  },
});

const LogoutDiv = styled('div')({
  marginTop: 20,
  marginRight: 20,
  float: 'right',
});

const LogoDiv = styled('div')({
  marginTop: 20,
  marginLeft: 20,
});

const LogoImg = styled('img')({
  maxWidth: '10%',
  maxHeight: '10%',
  minWidth: '100px',
  minHeight: '100px',
});
