import React, {FC, useEffect, useState} from 'react';
import {Button, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import {KRATOS_URL} from '~/config';
import {KratosFlowType, ResponseErrors, useInitialiseKratosFlow} from '~/hooks/kratos.hooks';
import {GoogleButton} from "~/components/GoogleButton";
import {EMAIL_REGEX} from "~/components/common/email-regex";
import {ErrorText} from "~/styledComponents";

interface LoginProps {
  originalUrlPath: string | undefined;
  originalFlowId: string | undefined;
  returnToUrl: string | undefined;
}

export const Login: FC<LoginProps> = ({originalUrlPath, originalFlowId, returnToUrl}: LoginProps) => {

  const [email, setEmail] = useState<string>('');

  const [password, setPassword] = useState<string>('');

  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);

  const [externalRedirectError, setExternalRedirectError] = useState<string>('')

  const {flowID, csrfToken, responseError, setResponseError} = useInitialiseKratosFlow(KratosFlowType.LOGIN, returnToUrl);

  useEffect(() => {
    if (originalUrlPath === '/error' ) {
      setExternalRedirectError('Error signing in.');
    }
  })

  const handleGoogleLogin = async () => {

    if (!flowID || !csrfToken) {
      setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      return;
    }

    const url = new URL(`${KRATOS_URL}/self-service/login`);
    url.searchParams.append('flow', flowID);
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        csrf_token: csrfToken,
        method: 'oidc',
        provider: 'google'
      }),
    });

    const responseJson = await response.json();

    if (response.status === 200) {
      if (!returnToUrl) {
        window.location.href = '/';
      } else {
        window.location.href = returnToUrl;
      }
    } else if (response.status === 422) {
      window.location.href = responseJson.redirect_browser_to
    } else {
      console.error('some other error')
    }
  };

  const handleLoginSubmit = async () => {
    if (!flowID || !csrfToken) {
      setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      return;
    } else {
      if (!EMAIL_REGEX.test(email)) {
        setIsInvalidEmail(true);
        return;
      }

      const url = new URL(`${KRATOS_URL}/self-service/login`);
      url.searchParams.append('flow', flowID);
      const response = await fetch(url.toString(), {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({identifier: email, password: password, csrf_token: csrfToken, method: 'password'}),
      });

      const responseJson = await response.json();

      if (response.status === 200) {
        if (!returnToUrl) {
          window.location.href = '/';
        } else {
          window.location.href = returnToUrl;
        }
      } else {
        setPassword('');
        if (responseJson?.ui?.messages.length > 0 && responseJson.ui.messages[0]?.id) {
          const kratosErrorCode = responseJson.ui.messages[0].id as number;
          switch (kratosErrorCode) {
            case 4000006:
              setResponseError(ResponseErrors.BAD_CREDENTIALS);
              break;
            default:
              setResponseError(ResponseErrors.UNKNOWN_KRATOS_ERROR);
              break;
          }
        } else {
          setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
        }
      }
    }
  };

  // Handles hitting enter button as form submission
  useEffect(() => {
    const page = document.getElementById("login-page")
    if (!page) {
      return console.error("login page failed to render")
    }

    const loginSubmitOnEnter = async (event: { key: string; preventDefault: () => void; }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        await handleLoginSubmit();
      }
    };
    page.addEventListener("keypress", loginSubmitOnEnter)
    return () => {
      page.removeEventListener("keypress", loginSubmitOnEnter)
    }
  }, [csrfToken, password, email])

  return (
    <CenteredDiv id="login-page">
      <LogoDiv>
        <LogoImg src={'/gradient_logo.svg'}/>
      </LogoDiv>
      <AlignedDiv>
        <GoogleButton
          style={'dark'}
          onClick={handleGoogleLogin}
          label={'Sign in with Google'}
        >
        </GoogleButton>
        {externalRedirectError && <ErrorText>{externalRedirectError}</ErrorText>}
      </AlignedDiv>
      <AlignedDiv>
        <TextField
          error={isInvalidEmail}
          helperText={isInvalidEmail ? 'Invalid email' : ''}
          id='email'
          name='email'
          label='Email'
          type='email'
          value={email}
          onChange={e => {
            setIsInvalidEmail(false);
            setEmail(e.target.value);
          }
          }
        />
      </AlignedDiv>
      <AlignedDiv>
        <TextField
          error={responseError !== undefined}
          helperText={responseError || ''}
          id='password'
          name='password'
          label='Password'
          type='password'
          value={password}
          onChange={e => {
            setResponseError(undefined);
            setPassword(e.target.value);
          }
          }
        />
      </AlignedDiv>
      <AlignedDiv>
        <Button
          onClick={handleLoginSubmit}
        >
          Login
        </Button>
      </AlignedDiv>
      <AlignedDiv>
        <Button
          onClick={() => {window.location.href = 'recover-password'}}
        >
          Forgot Password? Reset
        </Button>
      </AlignedDiv>
    </CenteredDiv>
  );
};

const CenteredDiv = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'grid',
  justifyContent: 'center',
  gridGap: '10px',
});

const AlignedDiv = styled('div')({
  display: 'block',
  justifySelf: 'center',
  alignItems: 'center',
  marginLeft: 20,
  marginRight: 20,
  '& input': {
    width: 'min(300px, 80vw)',
  },
});

const LogoDiv = styled('div')({
  width: 'auto',
  height: 'auto',
  marginBottom: 40,
  marginLeft: 'auto',
  marginRight: 'auto',
});

const LogoImg = styled('img')({
  width: '100%',
  height: '100%',
  minWidth: '250px',
  minHeight: '250px',
});
